import "../css/index.scss";
import $ from "./jquery.js";
const scrollTo = require("./jquery.scroll.js");
scrollTo($);
$(".nav li").on("click", function (e) {
  console.log(e)
  const id = $(e.target).attr("class");
  $(e.target).parent().addClass("active").siblings("li").removeClass("active");
  $.scrollTo(`#${id}`, 500);
});
// window.addEventListener("scroll", function (e) {
//   const ratio = $(document).width() / 1920;
//   const pl = ratio * 100
//   setTimeout(() => {
//     if (
//       e.target.scrollingElement.scrollTop >
//       $("#nav1").offset().top - pl
//     ) {
//       $(".nav li").eq(0).addClass("active").siblings("li")
//         .removeClass("active");
//     }
//     if (
//       e.target.scrollingElement.scrollTop >
//       $("#nav2").offset().top - pl
//     ) {
//       $(".nav li").eq(1).addClass("active").siblings("li")
//         .removeClass("active");
//     }
//     if (
//       e.target.scrollingElement.scrollTop >
//       $("#nav3").offset().top - pl || e.target.scrollingElement.scrollTop == Math.floor($('body').height() - window.innerHeight)
//     ) {
//       $(".nav li").eq(2).addClass("active").siblings("li")
//         .removeClass("active");
//     }
//   }, 500)

// });
